@import "../../theme";
@import "../../theme/media";

.subtitle {
  @include font("Chelsea Market", 400);
  color: $white;
  font-size: 3.75rem;
  line-height: 3.5rem;
  margin: 0;
  position: relative;
  text-align: center;

  @include media(lg) {
    font-size: 2.5rem;
    line-height: 2.4rem;
  }

  @include media(sm) {
    font-size: 2.25rem;
    line-height: 2.2rem;
  }

  @include media(xs) {
    font-size: 2rem;
    line-height: 1.9rem;
  }
}

.subtitleWithImage {
  margin: 1.5rem 0;
  text-align: center;

  .subtitle {
    display: inline-block;
  }

  @include media(xs) {
    margin: 1rem 0;
  }
}

.chrono {
  position: absolute;
  right: calc(100% + 1.5rem);
  top: 50%;
  transform: translateY(-50%);
  width: 7.8rem;

  @include media(lg) {
    width: 5rem;
  }

  @include media(sm) {
    display: none;
  }
}

.goalList {
  margin-left: 0;
}

.goalListItem {
  color: $green03;
  margin: 0.5rem 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include media(md) {
    grid-auto-flow: row;
    grid-template-columns: minmax(0, 1fr);
  }
}

.contentList {
  padding-left: 1rem;
}

.contentListItem {
  @include font("Poppins", 400);
  font-size: 1.5rem;
  line-height: 2rem;
  padding-left: 0.5rem;

  @include media(lg) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.intersessionWork {
  align-self: center;
  justify-self: end;
  width: 21rem;

  @include media(lg) {
    width: 15rem;
  }

  @include media(md) {
    grid-row: 1;
    justify-self: center;
  }

  @include media(sm) {
    max-width: 10rem;
    width: 100%;
  }
}
