@import "../../../theme";
@import "../../../theme/media";

.paragraph {
  align-self: flex-start;
}

.subtitle {
  color: $yellowC2;
  font-size: 2rem;
  margin: 2rem 0 1rem 0;
}
