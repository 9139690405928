@import "../../../theme";
@import "../../../theme/media";

.paragraph {
  display: flex;
  justify-content: center;
}

.list {
  font-weight: bold;
}

.listItem {
  margin-bottom: 0.5rem;
}
