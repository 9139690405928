@import "../../theme";
@import "../../theme/media";

.title {
  @include font("Chelsea Market", 400);
  color: $white;
  font-size: 2rem;
  margin: 1rem 0;
  opacity: 0.75;
  text-align: center;

  @include media(lg) {
    font-size: 1.75rem;
    line-height: 2rem;
    margin-bottom: 1.75rem;
  }

  @include media(md) {
    font-size: 1.5rem;
    line-height: 1.4rem;
    margin-bottom: 1.5rem;
  }

  @include media(sm) {
    font-size: 1.25rem;
    line-height: 1.2rem;
    margin-bottom: 1.25rem;
  }

  @include media(xs) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
}
