@import "../../theme";
@import "../../theme/media";

.grid {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-auto-rows: min-content;
  grid-column-gap: 2.5rem;
  grid-row-gap: 1rem;
  grid-template-rows: repeat(2, min-content);
  justify-items: center;

  &[data-direction="vertical"] {
    grid-auto-flow: row;
  }

  &[data-position="center center"] {
    align-items: center;
    justify-items: center;
  }

  &[data-position="left center"] {
    align-items: center;
    justify-items: start;
  }

  &[data-position="left top"] {
    align-items: start;
    justify-items: start;
  }

  @include media(lg) {
    grid-column-gap: 1.5rem;
    grid-row-gap: 0.75rem;
  }

  @include media(sm) {
    grid-auto-flow: row;
    grid-column-gap: 0;
  }
}

.padder {
  margin-bottom: 1rem;
}
