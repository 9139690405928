@import "../../theme";
@import "../../theme/media";

.list {
  @include font("Poppins", 400);
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include media(lg) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.listItem {
  svg {
    width: 1rem;

    @include media(lg) {
      width: 0.8rem;
    }

    @include media(sm) {
      width: 0.7rem;
    }

    @include media(xs) {
      width: 0.5rem;
    }
  }
}

.bold {
  font-weight: bold;
}

.icon {
  margin-right: 1rem;

  @include media(lg) {
    margin-right: 0.8rem;
  }

  @include media(sm) {
    margin-right: 0.7rem;
  }

  @include media(xs) {
    margin-right: 0.5rem;
  }
}

.padder {
  margin-bottom: 1rem;
}
