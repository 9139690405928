@import "../../theme";
@import "../../theme/media";

.paragraph {
  @include font("Poppins", 400);
  font-size: 1.4rem;
  line-height: 1.75rem;
  position: relative;

  strong {
    @include font("Poppins", 600);
  }

  h3 {
    @include font("Chelsea Market", 400);
    font-size: 3rem;
    margin: 0 0 1rem 0;

    @include media(lg) {
      font-size: 2rem;
      line-height: 1.9rem;
    }

    @include media(lg) {
      font-size: 1.5rem;
      line-height: 1.4rem;
    }
  }

  @include media(lg) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.padder {
  margin-bottom: 1rem;
}

.book {
  left: calc(100% + 0.5rem);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;

  @include media(lg) {
    width: 1.5rem;
  }
}

.bold {
  font-weight: bold;
}
