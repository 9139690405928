$black: #000000;
$blue00: #00aec3;
$blue03: #034147;
$brown4D: #4d2f0b;
$brown92: #926225;
$brownCF: #cf7e14;
$brownE1: #e18915;
$brownFF: #ffe6c7;
$gray22: #222222;
$gray44: #444444;
$grayC4: #c4c4c4;
$grayC9: #c9e9e6;
$grayEE: #eeeeee;
$green01: #013a41;
$green03: #034148;
$green1E: #1e8840;
$green2B: #2b5c5b;
$green35: #354f1b;
$green79: #79a42d;
$green8A: #88b75b;
$greenB1: #b1f56e;
$pink68: #682e48;
$pink71: #712435;
$pinkB2: #b27b97;
$pinkEA: #ea4d6f;
$pinkF4: #f4cbe3;
$red70: #701328;
$red: #f1677c;
$redF7: #f75507;
$teal24: #245657;
$teal5A: #5abebf;
$teal: #75b7b7;
$tealB0: #b0e6ef;
$white: #ffffff;
$yellow59: #594312;
$yellow7F: #7f692f;
$yellowC2: #c2f7ba;
$yellowE3: #e3ab1f;
$yellowFD: #fdaf18;

@mixin font($family, $weight) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
}
