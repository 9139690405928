@import "../../theme";
@import "../../theme/media";

.container {
  text-align: center;
}

.frame {
  position: relative;
}

@mixin img {
  pointer-events: none;
  position: absolute;

  @include media(sm) {
    display: none;
  }
}

.img1 {
  @include img;
  right: -10%;
  top: 35%;
  width: 75px;
}

.img2 {
  @include img;
  bottom: 14%;
  left: -4%;
  width: 61px;
}

.img3 {
  @include img;
  bottom: 15%;
  right: -2%;
  width: 58px;
}

.img4 {
  @include img;
  left: -11%;
  top: 35%;
  width: 63px;
}

.img5 {
  @include img;
  right: -2%;
  top: -4%;
  width: 44px;
}

.img6 {
  @include img;
  left: -2%;
  top: -12%;
  width: 55px;
}

.video {
  height: 28rem;
  width: 100%;
}
