@import "../theme";
@import "../theme/media";

.grid {
  @include font("Poppins", 600);
  align-content: center;
  align-items: center;
  color: $gray44;
  display: grid;
  font-size: 1.25rem;
  grid-auto-rows: min-content;
  grid-column-gap: 0.75rem;
  grid-template-columns: minmax(0, 1fr);
  padding: 0 3rem;

  @include media(lg) {
    font-size: 1rem;
    padding: 0.75rem;
  }

  @include media(md) {
    display: none;
  }
}

.header {
  grid-column: 2 / span 2;
  margin-bottom: 0.25rem;
}

.separator {
  grid-column: 1;
  grid-row: 1 / span 3;
  height: 100%;
  justify-self: center;
  margin-right: 1rem;
  max-height: 6rem;
  padding: 0;
}

.icon {
  color: $gray44;
  height: 1rem;
  width: 1rem;
}

.signOut {
  @include font("Poppins", 600);
  color: $gray44;
  font-size: 1.25rem;

  @include media(lg) {
    font-size: 1rem;
  }

  &:hover {
    text-decoration: underline;
  }
}
