@import "../../theme";
@import "../../theme/media";

$transition-duration: 150ms;

.background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  path:nth-of-type(2) {
    transition-duration: $transition-duration;
    transition-property: fill;
  }
}

@mixin button {
  @include font("Chelsea Market", 400);
  color: $gray44;
  font-size: 1.5rem;
  position: relative;

  &[disabled] {
    opacity: 0.5;
  }

  &:not([disabled]):hover {
    path:nth-of-type(2) {
      fill: darken($white, 10%);
    }
  }

  @include media(lg) {
    align-self: flex-end;
    font-size: 1.25rem;
  }

  @include media(sm) {
    font-size: 1rem;
  }
}

.buttonLeft {
  @include button;
  padding: 1.5rem 3rem 2.3rem 4rem;

  @include media(sm) {
    padding: 1rem 2.5rem 1.5rem 3.5rem;
  }
}

.buttonRight {
  @include button;
  padding: 2.3rem 4rem 3rem 3rem;

  @include media(lg) {
    padding: 1.5rem 4rem 2rem 3rem;
  }

  @include media(sm) {
    padding: 1.5rem 2rem 2rem 1rem;
  }
}

.label {
  z-index: 1;
}
