@import "../../theme";
@import "../../theme/media";

.subtitleContainer {
  @include font("Chelsea Market", 400);
  color: $white;
  font-size: 1.5rem;
  line-height: 1;
  margin-top: -0.5rem;
  text-align: center;

  @include media(lg) {
    font-size: 1.25rem;
    margin-top: -1rem;
  }

  @include media(md) {
    font-size: 1rem;
    margin-top: -0.5rem;
  }
}

.subtitle {
  position: relative;
}

.book {
  left: calc(100% + 0.5rem);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;

  @include media(lg) {
    width: 1.5rem;
  }
}
