@import "../../../theme";
@import "../../../theme/media";

.grid {
  display: grid;
  justify-self: center;
}

.paragraph {
  font-size: 0.9rem;
}
