@import "../../theme";
@import "../../theme/media";

@mixin heading {
  @include font("Chelsea Market", 400);
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.title {
  @include heading;
  font-size: 4rem;

  @include media(lg) {
    font-size: 3rem;
  }

  @include media(sm) {
    font-size: 2rem;
  }
}

.subtitle {
  @include heading;
  font-size: 1.75rem;
  opacity: 0.75;

  @include media(lg) {
    font-size: 1.5rem;
  }

  @include media(sm) {
    font-size: 1rem;
  }
}
